<!--onlyoffice 编辑器-->
<template>
  <div class="monitor-report">
    <div class="office">
      <div id="iframeEditor"></div>
    </div>
  </div>
</template>

<script>
function handleDocType(fileType) {
    let docType = '';
    let fileTypesDoc = [
        'doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'epub', 'fodt', 'htm', 'html', 'mht', 'odt', 'ott', 'pdf', 'rtf', 'txt', 'djvu', 'xps'
    ];
    let fileTypesCsv = [
        'csv', 'fods', 'ods', 'ots', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx'
    ];
    let fileTypesPPt = [
        'fodp', 'odp', 'otp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx'
    ];
    if (fileTypesDoc.includes(fileType)) {
        docType = 'text'
    }
    if (fileTypesCsv.includes(fileType)) {
        docType = 'spreadsheet'
    }
    if (fileTypesPPt.includes(fileType)) {
        docType = 'presentation'
    }
    return docType;
}
import { 
  GetAtion, //get
  PostAtion
} from '@/utils/http'
import {baseUrl} from '@/api/api';
export default {
  // name: 'Onlyoffice',
  props:{
    records:{
      type:Object,
      default:() =>{}
    }
  },
  data() {
    return {
      doctype: '',
      url: {
        // 文件变更历史记录-通过文件url查询是否变更记录
        queryByUrl: baseUrl+'product-server/office/officeFileChangeRecord/queryByUrl',
        // 获取历史版本记录
        history: baseUrl+'product-server/index/history',
        track: baseUrl+'product-server/index/IndexServlet/track',
      }
    }
  },
  mounted() {
    
    
  },
  watch: {
    // "$route.query": {
    //   deep: true,
    //   immediate: true,
    //   handler(val) {
    //     if (val.url) {
    //       this.setEditor(val)
    //       this.doctype = handleDocType(val.fileType)
    //     }
    //   }
    // },
    "records":{
      deep: true,
      immediate: true,
      handler(val) {
        console.log('======onlyoffice参数',val)
        if (val.url) {
          this.setEditor(val)
          this.doctype = handleDocType(val.fileType)

        }
      }
    }
  },
  methods: {
    getDocumentType(type) {
      if (!type) return;
      let word = ['doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'epub', 'fodt', 'fb2', 'htm', 'html', 'mht', 'odt', 'ott', 'oxps', 'pdf', 'rtf', 'txt', 'djvu', 'xml', 'xps']
      let cell = ['csv', 'fods', 'ods', 'ots', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx']
      let side = ['fodp', 'odp', 'otp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx']
      if (word.indexOf(type) !== -1) return 'word';
      if (cell.indexOf(type) !== -1) return 'cell';
      if (side.indexOf(type) !== -1) return 'side';
    },
    setEditor(option) {
      // console.log(option);
      if (!option.url || !option.attId){
        this.$message.warning('参数有误')
        return;
      } 
      
      this.doctype = handleDocType(option.fileType);
      GetAtion(this.url.queryByUrl, {
        // url: option.url,
        attId: option.attId
      }).then(res=>{
        if (res.success) {
          let arr = res.result
          let len = arr.length
          let newUrl = arr[(len - 1)].newFileUrl
          let fileKey = arr[(len - 1)].fileKey
          let url = this.url.history + '?fileUrl=' + option.url + '&version=' + '&attId=' + option.attId
          console.log('===================返回的历史arr',arr);
          console.log('===================返回的历史newUrl',newUrl);
          console.log('===================返回的历史fileKey',fileKey);
          PostAtion(url).then(res=>{
            if (res.success) {
              let data = res.result
              for (let index = 0; index < data.length; index++) {
                const element = data[index];
                data[index] = JSON.parse(element)
                console.log(data[index]);
              }
              this.openEditor(option, data, fileKey, newUrl)
            }
          })
        }
      })
    },
    openEditor(option, histArray, fileKey, newUrl) {
      var isEdit = false
      var mode = 'view'
      if (option.isType == 'handleEdit') {
        isEdit = true
        mode = 'edit'
      }
      
      const history = histArray[0];
      const historyData = histArray[1];
      var docEditor;
      var innerAlert = function (message) {
        if (console && console.log) console.log(message);
      };
      var onAppReady = function () {
        innerAlert("Document editor ready");
      };
      var onDocumentStateChange = function (event) {
        var title = document.title.replace(/\*$/g, "");
        document.title = title + (event.data ? "*" : "");
       
      };
      var onRequestEditRights = function () {
        location.href = location.href.replace(RegExp("mode=view\&?", "i"), "");
      };
      var onError = function (event) {
        if (event) innerAlert(event.data);
      };
      var onOutdatedVersion = function (event) {
        location.reload(true);
      };
      var replaceActionLink = function(href, linkParam) {
        var link;
        var actionIndex = href.indexOf("&actionLink=");
        if (actionIndex != -1) {
          var endIndex = href.indexOf("&", actionIndex + "&actionLink=".length);
          if (endIndex != -1) {
            link = href.substring(0, actionIndex) + href.substring(endIndex) + "&actionLink=" + encodeURIComponent(linkParam);
          } else {
            link = href.substring(0, actionIndex) + "&actionLink=" + encodeURIComponent(linkParam);
          }
        } else {
          link = href + "&actionLink=" + encodeURIComponent(linkParam);
        }
        return link;
      }

      var onMakeActionLink = function (event) {
          var actionData = event.data;
          var linkParam = JSON.stringify(actionData);
          docEditor.setActionLink(replaceActionLink(location.href, linkParam));
      };
      // office配置参数
      var config = {
        "logger": {
            "name": "com.niushuai1991.example.onlyoffice.entity.FileModel"
        },
        /**
         * @type
         * 接收参数 
         * embedded 全屏
         * desktop 电脑
         * mobile 手机
         */
        "type": "desktop",
          /**
         * @mode
         * 接收参数 
         * embedded 全屏
         * edit 电脑编辑
         * view 电脑查看
         */
        "mode": mode,
        "documentType": this.getDocumentType(option.fileType),
        "document": {
            "title": option.fileName,
            "url": newUrl,
            "fileType": option.fileType,
            "key": fileKey,
            "permissions": {
                /**
                 * @comment
                 * 接收参数 
                 * true
                 * false 查看
                */
                "comment": isEdit,
                "download": true,
                /**
                 * @comment
                 * 接收参数 
                 * true 编辑
                 * false 查看
                */
                "edit": isEdit,
                /**
                 * @fillForms
                 * 接收参数 
                 * true
                 * false 查看
                */
                "fillForms": isEdit,
                "modifyFilter": true,
                "modifyContentControl": true,
                /**
                 * @review
                 * 接收参数 
                 * true 编辑
                 * false 查看
                */
                "review": isEdit
            }
        },
        "editorConfig": {
              /**
             * @mode
             * 接收参数 
             * edit 编辑
             * view 查看
             */
            "mode": mode,
            // "callbackUrl": this.url.track + "?fileUrl=" + newUrl,//window._CONFIG['activitiURL'] + //原来的
            "callbackUrl": this.url.track + "?attId=" + option.attId + "&server=" + option.server,//window._CONFIG['activitiURL'] + //现在的
            "lang": "zh",
            "user": {
                "id": "uid-1",
                "name": "John Smith"
            },
            "customization": {
                "goback": {
                    // "url": window._CONFIG['activitiURL'].replace('/api', '') + '/#/dashboard/analysis'
                    "url": ''
                },
                "about": true,
                "feedback": false,
                "spellcheck": false,//拼写检查
                "forcesave":true, //手动保存
                "autosave":false //开启手动保存
            },
            /**
             * @embedded
             * 接收参数 对象object
             * saveUrl
             * embedUrl
             * shareUrl
             * toolbarDocked
            */
            // "embedded": {
            //     "saveUrl": "http://host.docker.internal:8087/file/iSignature_PDF_API%E4%B8%AD%E9%97%B4%E4%BB%B6%E6%8A%80%E6%9C%AF%E7%99%BD%E7%9A%AE%E4%B9%A6.doc",
            //     "embedUrl": "http://host.docker.internal:8087/file/iSignature_PDF_API%E4%B8%AD%E9%97%B4%E4%BB%B6%E6%8A%80%E6%9C%AF%E7%99%BD%E7%9A%AE%E4%B9%A6.doc",
            //     "shareUrl": "http://host.docker.internal:8087/file/iSignature_PDF_API%E4%B8%AD%E9%97%B4%E4%BB%B6%E6%8A%80%E6%9C%AF%E7%99%BD%E7%9A%AE%E4%B9%A6.doc",
            //     "toolbarDocked": "top"
            // },
            "canCoAuthoring": true,
            /**
             * @canUseHistory
             * 接收参数
             * true
             * false
            */
            "canUseHistory": false,
            /**
             * @canHistoryClose
             * 接收参数
             * true
             * false
            */
            "canHistoryClose": false,
            "canHistoryRestore": false,
            "canSendEmailAddresses": false,
            "canRequestEditRights": true,
            "canRequestClose": false,
            "canRename": false,
            "canMakeActionLink": true,
            "canRequestUsers": false,
            "canRequestSendNotify": false,
            "canRequestSaveAs": false,
            "canRequestInsertImage": false,
            "canRequestMailMergeRecipients": false,
            "canRequestCompareFile": false,
            "canRequestSharingSettings": false,
            "canRequestCreateNew": false
        },
        "width": "100%",
        "height": "100%",
        "events": {},
        "frameEditorId": "iframeEditor",
        "parentOrigin": ''
      };
      config.width = "100%";
      config.height = "100%";
      config.events = {
        "onAppReady": onAppReady,
        "onDocumentStateChange": onDocumentStateChange,
        'onRequestEditRights': onRequestEditRights,
        "onError": onError,
        "onOutdatedVersion": onOutdatedVersion,
        "onMakeActionLink": onMakeActionLink,
      };
      console.log('config:', config);
      if (history && historyData) { 
          config.events['onRequestHistory'] = function () {
              docEditor.refreshHistory(history);
          };
          config.events['onRequestHistoryData'] = function (event) {
              var ver = event.data;
              var histData = historyData;
              docEditor.setHistoryData(histData[ver]);
          };
          config.events['onRequestHistoryClose'] = function () {
              document.location.reload();
          };
      }
      // setTimeout(()=>{
          // var сonnectEditor = function () {
              docEditor = new DocsAPI.DocEditor("iframeEditor", config);
              
          // };
          console.log('window======================', window.addEventListener);
          // сonnectEditor()
          // if (window.addEventListener) {
          //     console.log('addEventListener======================сonnectEditor');
          //     window.addEventListener("load", сonnectEditor);
          // } else if (window.attachEvent) {
          //     console.log('attachEvent======================сonnectEditor');
          //     window.attachEvent("load", сonnectEditor);
          // }
      // }, 5000)
    },
    
  }
}
</script>

<style scoped lang="less">
.monitor-report {
  height: 100%;
    .office {
      height: 100%;
    }
}
</style>
